export default function BetaBadge(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="34"
      viewBox="0 0 60 34"
      {...props}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-272 -55)">
          <g transform="translate(272 55)">
            <rect
              width="58"
              height="32"
              x="1"
              y="1"
              stroke="url(#linearGradient-1)"
              strokeWidth="2"
              rx="6"
            ></rect>
            <path
              fill="url(#linearGradient-1)"
              fillRule="nonzero"
              d="M17.425 23c2.556 0 4.092-1.278 4.092-3.378v-.017c0-1.56-1.079-2.706-2.698-2.88v-.142c1.179-.199 2.1-1.32 2.1-2.564v-.017c0-1.835-1.353-2.98-3.602-2.98h-4.815V23h4.923zm-.855-6.881h-2.208V12.54h2.523c1.378 0 2.183.656 2.183 1.785v.016c0 1.162-.855 1.777-2.498 1.777zm.481 5.362h-2.69v-3.943h2.582c1.752 0 2.673.664 2.673 1.95v.017c0 1.287-.888 1.976-2.565 1.976zm10.726 1.693c2.308 0 3.553-1.328 3.852-2.515l.016-.075-1.735.009-.033.066c-.216.465-.905 1.038-2.058 1.038-1.486 0-2.433-1.005-2.466-2.731h6.392v-.631c0-2.706-1.544-4.524-4.06-4.524-2.514 0-4.141 1.884-4.141 4.698v.009c0 2.855 1.593 4.656 4.233 4.656zm2.183-5.495h-4.582c.158-1.552 1.088-2.39 2.316-2.39 1.22 0 2.125.78 2.266 2.39zm7.63 5.38c.348 0 .68-.042.97-.092V21.53a5.935 5.935 0 01-.68.033c-.888 0-1.253-.398-1.253-1.37v-4.79h1.934v-1.419h-1.934v-2.274h-1.835v2.274h-1.41v1.42h1.41v5.221c0 1.735.814 2.432 2.798 2.432zm5.845.09c1.195 0 2.141-.514 2.673-1.427h.14V23h1.785v-6.167c0-1.893-1.278-3.022-3.544-3.022-2.05 0-3.47.988-3.686 2.465l-.008.058h1.735l.008-.033c.216-.639.872-1.004 1.868-1.004 1.22 0 1.843.548 1.843 1.536v.796l-2.44.142c-2.15.132-3.363 1.07-3.363 2.68v.017c0 1.636 1.27 2.681 2.989 2.681zm.481-1.436c-.971 0-1.677-.49-1.677-1.32v-.016c0-.814.565-1.262 1.81-1.336l2.2-.142v.772c0 1.162-.988 2.042-2.333 2.042z"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}
