import cn from 'clsx'
// @ts-ignore
import Plx from 'react-plx'
import MarkLogo from '@components/svg/mark-logo'

import styles from './modal-section.module.css'

const parallaxData = [
  {
    start: 0,
    end: typeof window !== 'undefined' ? window?.innerHeight * 2 : 700,
    properties: [
      {
        startValue: 50,
        endValue: -250,
        property: 'translateY',
      },
    ],
  },
]

export default function ModalSection() {
  return (
    <section className="flex flex-col items-center p-8 sm:p-20 text-white">
      <Plx parallaxData={parallaxData}>
        <div
          className={cn(
            'rounded-xl border border-solid p-8 flex flex-col items-center',
            styles.modalGraphic,
          )}
          style={{ borderColor: '#2C2C2C', backgroundColor: '#0D0D0D' }}
        >
          <MarkLogo />
          <div className="mt-5 mb-8">Payment</div>
          <div
            className={cn(
              'rounded-lg border border-solid p-2 flex items-center',
              styles.input,
            )}
            style={{ borderColor: '#2C2C2C' }}
          >
            <img
              src="/icons/trx_white.png"
              style={{ width: 28, height: 28 }}
              className="opacity-40 mr-2"
            />
            <span className="text-sm mr-1">TRON</span>
            <span className="text-xs opacity-50">(TRX)</span>
          </div>

          <hr
            className="mt-4 mb-4 border-bottom border-solid w-full h-0"
            style={{ borderColor: '#2C2C2C' }}
          />

          <div className="flex items-end justify-between text-sm w-full">
            <span>Total in USD:</span>
            <span className="font-bold">$50</span>
          </div>
          <div className="flex items-end justify-between text-sm w-full mt-3">
            <span>Total in TRX:</span>
            <span className="font-bold">506.378049</span>
          </div>

          <hr
            className="mt-4 mb-4 border-bottom border-solid w-full h-0"
            style={{ borderColor: '#2C2C2C' }}
          />

          <div className="brand-button-outline-wrapper rounded-lg mt-4 w-full">
            <div className="rounded-lg" style={{ backgroundColor: '#0d0d0d' }}>
              <div className="brand-button-outline p-2 w-full text-center">
                Confirm Payment
              </div>
            </div>
          </div>
        </div>
      </Plx>
    </section>
  )
}
