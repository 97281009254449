import Link from 'next/link'
import MainLogo from '@components/svg/main-logo'
import { Fragment, useContext } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { UserContext } from '@lib/user-context'
import BetaBadge from '@components/svg/beta-badge'

export default function Nav() {
  const [user] = useContext(UserContext)

  return (
    <Popover className="relative w-full">
      <nav className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link href="/">
              <a className="flex items-center w-36 sm:w-48">
                <MainLogo />
                <BetaBadge className="ml-2" />
              </a>
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button
              className="rounded-md p-2 inline-flex items-center justify-center text-indigo-500 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              style={{ backgroundColor: '#1a1a1a' }}
            >
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>

          <div className="hidden items-center text-white text-sm sm:flex">
            <ul className="flex justify-between">
              <li className="flex items-center mx-4">
                <Link href="/#pricing">
                  <a>Pricing</a>
                </Link>
              </li>
              <li className="flex items-center mx-4">
                <Link href="/about">
                  <a>About</a>
                </Link>
              </li>
              {!user && (
                <li className="flex items-center mx-4">
                  <Link href="/login">
                    <a>Log In</a>
                  </Link>
                </li>
              )}
            </ul>

            <Link
              href={
                (user as any)?.loading ? '#' : user ? '/dashboard' : '/signup'
              }
            >
              <a className="ml-2 flex items-center justify-center brand-button rounded-md w-48 h-9 text-center z-10">
                {user && !(user as any).loading ? (
                  'Go To Dashboard →'
                ) : (user as any)?.loading ? (
                  <div className="lds-ellipsis-small opacity-50">
                    <div />
                    <div />
                    <div />
                    <div />
                  </div>
                ) : (
                  'Sign Up'
                )}
              </a>
            </Link>
          </div>
        </div>
      </nav>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-10"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-black text-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <Link href="/">
                  <a className="flex items-center w-36 sm:w-48">
                    <MainLogo />
                  </a>
                </Link>

                <div className="-mr-2">
                  <Popover.Button
                    className="rounded-md p-2 inline-flex items-center justify-center hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                    style={{ backgroundColor: '#1a1a1a' }}
                  >
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav
                  className="grid gap-y-8 pb-2 mb-5 border-b border-solid"
                  style={{ borderColor: '#555' }}
                >
                  <ul className="flex flex-col items-end">
                    <li className="flex items-center my-4">
                      <Link href="/#pricing">
                        <a>Pricing</a>
                      </Link>
                    </li>
                    <li className="flex items-center my-4">
                      <Link href="/about">
                        <a>About</a>
                      </Link>
                    </li>
                    <li className="flex items-center my-4">
                      <Link href="/contact">
                        <a>Log In</a>
                      </Link>
                    </li>
                  </ul>
                </nav>

                <Link href="/signup">
                  <a className="ml-2 flex items-center brand-button py-2 px-8 rounded-md">
                    Sign Up
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
