function MarkLogo(...props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="42"
      viewBox="0 0 56 42"
      {...props}
    >
      <defs>
        <linearGradient
          id="linearGradient-1"
          x1="100%"
          x2="0%"
          y1="21.875%"
          y2="78.125%"
        >
          <stop offset="0%" stopColor="#B26DDD"></stop>
          <stop offset="100%" stopColor="#6863CD"></stop>
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="url(#linearGradient-1)" transform="translate(-692 -834)">
          <path d="M742 834a6 6 0 016 6v30a6 6 0 01-6 6h-44a6 6 0 01-6-6v-30a6 6 0 016-6h44zm-22 8c-7.18 0-13 5.82-13 13s5.82 13 13 13 13-5.82 13-13-5.82-13-13-13zm0 3c5.523 0 10 4.477 10 10s-4.477 10-10 10-10-4.477-10-10 4.477-10 10-10z"></path>
        </g>
      </g>
    </svg>
  )
}

export default MarkLogo
